<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">Collection Report</h1>
                    </div><!-- /.col --> 
                </div><!-- /.row --> 
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                     <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="rider_id">Rider</label>
                                            <v-select name="reason_id"
                                                v-model="search.rider_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= riderList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                     <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="rider_id">Collected From</label>
                                            <select id="collection" v-model="search.collection" class="form-control">                                            
                                                <option :value="0">Select</option>
                                                <option :value="1">Rider</option>
                                                <option :value="2">Hub</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12" v-if="authUser.role_id <= 3">
                                        <div class="form-group">
                                            <label for="title">{{ $t('globalTrans.hub') }}</label>
                                            <v-select name="hub_id"
                                                v-model="search.hub_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= hubList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="start_date">Start Date</label>
                                            <input type="date" id="start_date" v-model="search.start_date" class="form-control" />
                                        </div> 
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="end_date">End Date</label>
                                            <input type="date" id="end_date" v-model="search.end_date" class="form-control" />
                                        </div> 
                                    </div>
                                     <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchData" style="margin-top:28px;"><i class="fa fa-search"></i></button>
                                            <button class="btn btn-warning btn-sm ml-2" @click.prevent="reload" style="margin-top:28px;"><i class="fa fa-sync-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loading">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>Status</th>
                                                    <th>Total Parcel</th>
                                                    <th>Price</th>
                                                    <th>Collection</th>
                                                    <th>Ratio</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in listData" :key="index">
                                                    <td><span class="label p-1" :class="'status-'+item.status">{{ getStatus(item.status) }}</span></td>
                                                    <td>
                                                        <span @click="gotToUrl(item)" style="color:blue;font-weight:bold;cursor:pointer">
                                                            {{ item.total_parcel }}
                                                        </span>
                                                    </td>
                                                    <td>{{ item.total_price }}</td>
                                                    <td>{{ item.total_collected }}</td>
                                                    <td>{{ item.ratio }} %</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr v-if="summary">
                                                    <th>Total</th>
                                                    <th>{{ summary.grandTotal }}</th>
                                                    <th>{{ summary.totalPrice }}</th>
                                                    <th>{{ summary.totalCollected }}</th>
                                                    <th>{{ summary.totalRatio }} %</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import config from '@/config'
    import moment from 'moment'
    export default {
        name: 'CollectionReport',
        data () {
            return {              
                loading: false,             
                listData: [],
                summary: {
                    grandTotal: 0,
                    totalPrice: 0,
                    totalCollected: 0,
                    totalRatio: 0
                },
                search: {
                    rider_id: '',
                    hub_id: '',
                    collection: 0,
                    start_date: moment().subtract(1,'d').format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD')
                }
            }
        },
        created(){
            this.loadData();
        },
        computed : {
            riderList: function () {
                if (this.authUser.role_id == 7) {
                    return this.$store.state.commonObj.riderList.filter(el => el.hub_id === this.authUser.hub_id)
                }
                return this.$store.state.commonObj.riderList
            },
            hubList () {
                return this.$store.state.commonObj.hubList
            },
            authUser () {
                return this.$store.state.authUser
            }
        },
        methods:{
            searchData () {
                this.loadData()
            },
            async loadData(){     
                this.loading = true
                const params = this.authUser.role_id == 7 ? Object.assign({}, this.search, { hub_id: this.authUser.hub_id }) : this.search  
                const response = await config.getData('/accounts/collection/report', params)
                this.loading = false
                if (response.status == 200){
                    this.listData = this.getRelationalData(response.data)
                } else {
                    this.listData = []
                } 
            },
            getRelationalData (data) {
                let totalPrice      = 0
                let totalCollected  = 0
                let totalRatio      = 0
                const grandTotal = data.reduce((acc, item) => acc + item.total_parcel, 0);

                const finalData = data.map(item => {
                    const tmpRatio = parseFloat((item.total_parcel/grandTotal) * 100)
                    totalPrice += item.total_price
                    totalCollected += item.total_collected
                    totalRatio += tmpRatio

                    return Object.assign(item, { grand_total: grandTotal, ratio: tmpRatio.toFixed(2) })
                })

                Object.assign(this.summary, { grandTotal: grandTotal, totalPrice: totalPrice.toFixed(2), totalCollected: totalCollected.toFixed(2), totalRatio: totalRatio.toFixed(2) })

                return finalData
            },
            reload () {
                this.commonStatus = []
                this.search = Object.assign({}, {
                    rider_id: '',
                    hub_id: '',
                    collection: 0,
                    start_date: moment().subtract(1,'d').format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD')
                })
                this.orderList = this.ordersData = this.printorders = []
                this.totalSelected = 0
            },
            getStatus (status) {
                const tmpStatus = this.$store.state.statusList.find(tmp => tmp.id == status)
                return typeof tmpStatus !== 'undefined' ? tmpStatus.text : ''
            },
            gotToUrl (item) {
                if (item.status != 17 && item.status != 18) {
                    this.$router.push(`/order?date_parameter_id=4&start_date=${this.search.start_date}&end_date=${this.search.end_date}&query_status=${item.status}&collection=${item.collection}`)
                }                
            }
        }
    }
</script>
<style>
    .w-35{
        width:35px;
    }
</style>